import React from 'react'
import Layout from '../../Shared/Layout'
import ContactUs from '../../components/ContactUs'

const Contact: React.FC = () => {
  return (
    <Layout>
      <ContactUs />
    </Layout>
  )
}

export default Contact