import React from 'react';
import { Link } from 'react-router-dom';
import { CharitySupportData } from '../CharitySupport';

const CharitySupportCard: React.FC<{ data: CharitySupportData }> = ({ data }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
                src={data?.image || '/about.jpg'}
                alt={data?.condition || 'Charity Condition'}
                className="h-40 w-full object-cover rounded-t-lg"
            />
            <div className="p-4">
                <b className="text-lg">{data?.condition}</b>
                <div className="text-md my-2 text-gray-700"><span className='text-black font-bold'>Charity Name: </span>{data?.charity_name}</div>
                <div className='flex justify-between'>
                    {data?.nhs_guidance && (
                        <Link to={data?.nhs_guidance} target="_blank" className="text-blue-500 underline block my-1">
                            NHS Guidance
                        </Link>
                    )}
                    {data?.national_charity_link && (
                        <Link to={data?.national_charity_link} target="_blank" className="text-blue-500 underline block my-1">
                            National Charity Link
                        </Link>
                    )}
                </div>
                <div className="text-sm text-gray-600 mt-2" dangerouslySetInnerHTML={{__html:data?.safety_netting_advice }} />
            </div>
        </div>
    );
};

export default CharitySupportCard;