import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <main className='max-w-[100vw] min-h-[100vh]'>
            <Header />
            <div className="min-h-[90vh]">
                {children}
            </div>
            <Footer />
        </main>
    );
}

export default Layout;
