import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const menuItems = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
  { name: 'Find Charity Support', path: '/find-charity-support' },
  { name: 'Nearby Services', path: '/nearby-services' },
  { name: 'Medication Information', path: '/medication-information' },
];

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white border-b-[2px] px-10 flex justify-between items-center h-[6vh] lg:h-[10vh]">
      <img src='/logo.png' alt='logo' className='h-[7vh]'/>
      <div className="lg:hidden" onClick={toggleMenu}>
        {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>
      <div className="hidden lg:flex gap-5">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`py-1 px-4 no-underline cursor-pointer 
              ${location.pathname === item.path ? 'bg-[#00a7cc] text-white rounded-md' : 'text-black hover:bg-[#00a7cc90] hover:text-white hover:rounded-md'}`}
          >
            {item.name}
          </Link>
        ))}
      </div>
      <Link
        to="/contact-us"
        className="hidden lg:block bg-[#00a7cc] border border-[#00a7cc] py-2 px-4 rounded-md text-white no-underline hover:bg-white hover:text-[#00a7cc] cursor-pointer"
      >
        Contact Us
      </Link>

      {isOpen && (
        <div className="absolute top-[6vh] border-t-2 left-0 w-full h-fit bg-white z-[1000000] flex flex-col items-center space-y-5 py-10 md:hidden">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              onClick={toggleMenu} // Close the menu after clicking a link
              className={`py-3 w-full text-center no-underline cursor-pointer 
                ${location.pathname === item.path ? 'bg-[#00a7cc] text-white' : 'text-black hover:bg-[#00a7cc90] hover:text-white'}`}
            >
              {item.name}
            </Link>
          ))}
          <Link
            to="/contact-us"
            onClick={toggleMenu} // Close the menu after clicking
            className="bg-[#00a7cc] border border-[#00a7cc] py-3 px-6 rounded-md text-white no-underline hover:bg-white hover:text-[#00a7cc] cursor-pointer"
          >
            Contact Us
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
