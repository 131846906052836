import React from 'react'

const Footer = () => {
    return (
        <footer>
            <p className="py-4 text-center text-sm bg-black text-white">
                © 2024 All rights reserved.
            </p>
        </footer>
    )
}

export default Footer