import React from 'react';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './Pages/About';
import Charity from './Pages/Charity';
import Nearby from './Pages/Nearby';
import Medication from './Pages/Medication';
import Contact from './Pages/Contact';

function App() {

  return (
    // React Routers 
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/find-charity-support" element={<Charity />} />
        <Route path="/nearby-services" element={<Nearby />} />
        <Route path="/medication-information" element={<Medication />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="*" element={< >no</>} />
      </Routes>
    </Router>

  );
}

export default App;
