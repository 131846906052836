import React from 'react';
import Layout from '../../Shared/Layout';
import AboutUs from '../../components/AboutUs';
import ServicesSection from '../../components/OurServices';

const About: React.FC = () => {
  return (
    <Layout>
      <div>
        <AboutUs/>
        <ServicesSection/>
      </div>
    </Layout>
  )
}

export default About