import React from 'react'
import Layout from '../../Shared/Layout'
import Chatbot from '../../components/Chatbot'
import { FaQuestionCircle } from 'react-icons/fa';

const Medication: React.FC = () => {
  return (
    <Layout>
      <div className='h-[90vh] w-[95%] mx-auto pb-12'>
        <FaQuestionCircle size={15} color='#00a7cc' className='ml-auto my-2' />
        <Chatbot clientId='c06299a1-574d-4fda-9439-6285eb9c8c12' />
      </div>
    </Layout>
  )
}

export default Medication