import React from 'react';
import Layout from '../../Shared/Layout';
import CharitySupport from '../../components/CharitySupport';

const Charity: React.FC = () => {
  return (
    <Layout>
      <CharitySupport/>
    </Layout>
  );
};

export default Charity;