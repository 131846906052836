import React from 'react';

interface GuideModalProps {
  show: boolean;
  step: number;
  message: string;
  onNext: () => void;
  onYes?: () => void;
  position: { top: string; left: string };
  borderRadius: string;
}

const GuideModal: React.FC<GuideModalProps> = ({ show, step, message, onNext, onYes, position, borderRadius }) => {
  if (!show) return null;

  const modalStyle: React.CSSProperties = {
    ...position,
    borderRadius,
    padding: '20px',
    background: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000
  };

  return (
    <>
      <div className="overlay"></div>
      <div style={modalStyle} className="guide-modal">
        <p>{message}</p>
        {
          step !== 3 && (
            <>
              {
                step === 2 ? <div className='flex gap-2'>
                  <button onClick={onYes} className="btn">Yes</button>
                  <button onClick={onNext} className="btn">No</button>
                </div> : <button onClick={onNext} className="btn">Next</button>
              }
            </>
          )
        }
      </div>
    </>
  );
}

export default GuideModal;
