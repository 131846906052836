import React, { useEffect, useState } from 'react';
import Layout from '../../Shared/Layout';
import Chatbot from '../../components/Chatbot';
import GuideModal from '../../components/GuideModal';

interface GuideStep {
  message: string;
  position: { top: string; left: string; transform?: string };
  borderRadius: string;
}

const guideSteps: GuideStep[] = [
  {
    message: "Welcome to The Patient Centre!",
    position: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
    borderRadius: "8px"
  },
  {
    message: "You can interact with the bot by typing here. Type 'Hi' to start!",
    position: { top: '75%', left: '10%' },
    borderRadius: "8px 8px 8px 0px"
  },
  {
    message: "Do you require emergency treatment?",
    position: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
    borderRadius: "8px"
  },
  {
    message: "Seek emergency help from your nearest hospital or call 999.",
    position: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
    borderRadius: "8px"
  }
];

const Home: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [showGuide, setShowGuide] = useState<boolean>(false);
  const [showEmergencyModal, setShowEmergencyModal] = useState<boolean>(false);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('usedSiteBefore');
    if (!hasVisitedBefore) {
      setShowGuide(true);
    }
  }, []);

  const handleNextStep = () => {
    if (step === 2) {
      localStorage.setItem('usedSiteBefore', 'true');
      setShowGuide(false);
    } else {
      setStep(step + 1);
    }
  };

  const handleEmergencyYes = () => {
    setShowEmergencyModal(true);
    setShowGuide(false);
  };

  return (
    <Layout>
      <div className='h-[90vh] w-[95%] mx-auto py-4'>
        <Chatbot clientId='78dbe248-9a5e-434a-8302-c3473ed089a0'/>
        {showGuide && (
          <GuideModal
            show={true}
            step={step}
            message={guideSteps[step].message}
            position={guideSteps[step].position}
            borderRadius={guideSteps[step].borderRadius}
            onNext={handleNextStep}
            onYes={handleEmergencyYes}
          />
        )}
        {showEmergencyModal && (
          <GuideModal
          show={showEmergencyModal}
          step={3}
          message={guideSteps[3].message}
          position={guideSteps[3].position}
          borderRadius={guideSteps[3].borderRadius}
          onNext={handleNextStep}
        />
      )}
      </div>
    </Layout>
  );
};

export default Home;
