import { Configuration, getClient, Webchat, WebchatProvider } from '@botpress/webchat';
import { buildTheme } from "@botpress/webchat-generator";
import React from 'react';

import { ChatbotProps } from './props';

const Chatbot: React.FC<ChatbotProps> = ({ clientId }) => {
  const client = getClient({ clientId });

  const config: Configuration = {
    composerPlaceholder: "Ask me anything...",
    botName: "The Patient Centre",
    botAvatar: "doctor.png",
  };

  const { theme, style } = buildTheme({
    themeName: "prism",
    themeColor: "#634433",
  });

  return <>
    <style>{style}</style>
    <WebchatProvider client={client} configuration={config} theme={theme}>
      <Webchat />
    </WebchatProvider>
  </>;
}

export default Chatbot;
export type { ChatbotProps };
